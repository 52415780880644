@import '~@Styles/vars';

.linkButton {
  cursor: pointer;
  text-decoration-line: underline;
  text-underline-position: under;
}

.textAlignCenter {
  text-align: center;
}

.blue {
  color:$electricBlue;
}

.red{
  color: $red;
}
