@import '~@Styles/vars';

.optionBoxesContainer {
  flex-wrap: wrap;
  gap: 4px;
  margin-top: 12px;
}

.cardBox {
  display: flex;
  gap:6px;
  align-items: center;
  padding: 3px 4px 3px 4px;
  font-size: $fontSize-16;
  background: $white;
  border: 0.5px solid $pearl-white;
  border-radius: $border-radius;
  box-shadow: 0 2px 6px -14px $shadowColor;
  cursor: pointer;
}

.ml4 {
  margin-left: 4px;
  cursor: pointer;
}

.logoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: $border-radius-round;
  
  img {
    max-width: 91%;
    max-height: 100%;
    border-radius: $border-radius-round;
  }
}

.initialLogoContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  color: $white;
  font-weight: $bold;
  font-size: $fontSize-21;
  background: $violet-light;
  border-radius: $border-radius-round;
}

.handSymbol {
  cursor: pointer;
}