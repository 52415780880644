@import '~@Styles/vars';

.serviceProviderSteps {
  display: flex;
  gap: 24px;
  align-items: flex-start;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 16px;
  overflow: auto !important;
  background: $tableHeaderBackgound;
  @media (max-width: $tablet) { 
    gap: 12px; // Reduce space on tablets & mobiles
  }
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 120px;
  text-align: center;
}

.stepCircle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  color: $white;
  font-weight: $bold;
  font-size: $fontSize-16;
  background: $gray;
  border: 2px solid transparent;
  border-radius: $border-radius-round;
  transition: all 0.3s ease-in-out;
  @media (max-width: $tablet) { 
    width: 32px;
    height: 32px;
    font-size: $fontSize-12;
  }
  @media (max-width: $mobile) {
    width: 28px;
    height: 28px;
    font-size: $fontSize-10;
  }
}

.active {
  background: $profRegisterBackground;
  box-shadow: 0 4px 10px $profRegisterShadow;
  transform: scale(1.2);
}

.stepCircle.completed {
  background: $profRegisterBackground;
  border-color: $profRegisterBackground;
}

.tickIcon {
  color: $white;
  font-size: $fontSize-18;
}

.stepLabel {
  min-height: 40px;
  margin-top: 8px;
  color: $textPrimary;
  font-weight: $semiBold;
  font-size: $fontSize-14;
  @media (max-width: $tablet) {
    font-size: $fontSize-12;
  }

  @media (max-width: $mobile) {
    font-size: $fontSize-10;
  }
}

.iconColor {
  path {
    fill: $white;
  }
}

.unfinished {
  color: $black; /* Change text color to match border */
  font-weight: $bold; /* Optional: Adjust font weight */
  background: $transparent; /* Remove the background color */
  border: 2px dashed $black; /* Use a dashed border */
}

.clickable{
  cursor: pointer;
}
