@import '~@Styles/vars';
@import '~@Styles/mixins';
  
.suggestions {
  position: absolute;
  z-index: $zindex-table;
  width: 100%;
  max-height: 18rem;
  margin: 0;
  margin-top: 0;
  padding: 0;
  overflow-y: auto;
  font-size: $fontSize-18;
  list-style: none;
  background-color: $white;
  border: 0.5px solid $footerColor;
  border-top-color: $gray;
  border-radius: $border-radius-zero;
  border-bottom-right-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
  box-shadow: $no-shadow;
  li {
    height: 55px;
    max-height: 195px;
    padding: 15px;
    background-color: $white;
  }

  li:hover {
    cursor: pointer;
  }  
}

.logoGap {
  gap: 15px;
}

.logoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: $border-radius-bigger;

  img {
    max-width: 91%;
    max-height: 100%;
    border-radius: $border-radius-bigger;
  }
}

.searchIcon {
  position: absolute;
  top:5px;
  right: 1%;
  cursor: pointer;
  transition: transform 0.3s, opacity 0.3s;
  path {
    fill: $dividerColor;
  }
}

.initialLogoContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  color: $white;
  font-weight: $bold;
  font-size: $fontSize-21;
  background: $violet-light;
  border-radius: $border-radius-bigger;
}

.briefCaseContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: $white;
  border-radius: $border-radius-round;
  path {
    fill: $buttonTextColor
  }
}

.listHover {
  &:hover {
    background: $autoCompleteHover;
    border-radius: $border-radius;
    cursor: pointer;
  }
}

.suggestionForCompany{
  position: absolute;
  width: 100%;
}

.noMatch {
  padding: 8px;
  color: $autoGray;
  font-size: $fontSize-18;
  text-align: center;
}

.inputTextBox{
  max-height: 50px !important;
  border-radius: $border-radius-bigger !important;
  box-shadow: 0 2px 4px $case-shadow;
  &::placeholder {
    font-size: $fontSize-16 !important;
  }
}

.searchIconSmart {
  top: 0.5rem !important;
}

