@import '~@Styles/vars';

.headerLayout {
  // position: fixed;
  z-index: $z-index1;
  width: 100%;
  padding: 10px 0 10px 0;
  padding: 15px 0 12px 40px;
  background: $menuBackgrounndColor;
}

.mainContentLayout {
  width: 100%;
  background: $white;
}

.infoContent {
  padding-top: 85px;
  @media (max-width: $tablet) {
    padding-left: 34px;
  }
  @media (min-width: $tablet) and (max-width: $desktop-1181) {
    padding-left: 34px;
  }
  @media (max-width: $mobile) {
    padding: 80px 16px 0 16px;
  }
}

.closeFreeze {
  width: 574px;
  font-size: $fontSize-24;
  line-height: 35px;

  @media (max-width: $tablet) {
    width: unset;
    font-size: $fontSize-20;
  }
  @media (min-width: $tablet) and (max-width: $desktop-1181) {
    width: unset;
  }
  @media (max-width: $mobile) {
    width: unset;
  }
}

.tickIcon {
  flex-shrink: 0;
}

.points{
  gap: 18px;
  margin-bottom: 16px;
  @media (max-width: $mobile) {
    margin-top: 16px;
    padding-right: 16px;
    padding-left: 16px;
  }
}

.gapBetween {
  position: relative;
  gap: 20px;
}

.mb40 {
  @media (max-width: $tablet) {
    margin-bottom: 100px;
  }
}

.logoPlacement{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 315px;
  height: 200px;
  @media (max-width: $tablet) {
    margin-bottom: 40px;
  }
}

.logo {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.threeMobileWidth {
  width: 50px;
}

.bottomContainer {
  width: 100%;
  background: $fileUploadBackground;
}

.advantagesContainer {
  width: 510px;
  margin-top: 30px;
  padding: 32px 32px 48px 32px;
  background: $white;
  border-radius: $border-radius-toggle;
  box-shadow: $case-card;
  @media (max-width: $tablet) {
    width: 352px;
  }
  @media (min-width: $mobile) and (max-width: $lessThanTablet) {
    width: unset;
    margin-left: unset;
  }
  @media (max-width: $mobile) {
    width: unset;
    margin-left: unset;
  }
}

.titlesLineHeight {
  line-height: 47px;
}

.mb8{
  margin-bottom: 8px;
}

.mb20{
  @media (max-width: $tablet) {
    margin-bottom: 19px;
  }
}

.formPlacement {
  padding-top: 30px;
  padding-right: 16px;
  padding-left: 16px;
}

.advDiv {
  padding-right: 16px;
  padding-left:16px;
}

.mb100 {
  margin-bottom: 30rem;
  @media (max-width: $mobile) {
    margin-bottom: unset;
  }
}

.mb276 {
  margin-bottom: 276px;
}

.pb100 {
  @media (max-width: $tablet) {
    padding-bottom: 103px;
  }
}

.mobEmailSentTo {
  padding-top: 100px;
}

.flexBox {
  display: flex;
  gap:40px;
}

.advantageTitle {
  margin-bottom: 28px;
  font-size: $fontSize-24;
  line-height: 31.39px;
}

.recommendedContainer {
  flex-wrap: wrap;
  gap:8px;
  @media (max-width: $res1110) {
    margin-top: 25px;
  }
 
}
