@import '~@Styles/vars';

.spacing {
  margin-top: 10px;
  margin-bottom: 10px;
}

.buttonCenter {
  text-align: center;
}

.links {
  text-decoration-line: underline;
  color: $electricBlue;
  text-underline-position: below;
}

.error {
  color: $red;
  font-size: $fontSize-14;
  font-family: $helveticaFamily;
  
  path{
    fill: $red;
  }
}

.withErrorIcon {
  align-items: center;
  width: 100%;
  margin-top: 1px;
}
.message {
  vertical-align: top;
}
.iconPlacing {
  margin-right:6px;
}

.guidance{
  line-height: 19.68px;
}

.passwordCorrect {
  color: $successColor;
  font-size: $fontSize-14;
  font-family: $helveticaFamily;
  
  path{
    fill: $successColor;
  }
}


.googleButton {
  width: 100%;

  div {
    margin-left: 2px;
    padding: 8px !important;
  }
  span {
    width: 100%;
    font-weight: $bold !important;
    font-size: $fontSize-17;
    text-align: center;
  }
}

.mt16 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.w50 {
  width: 50% !important;
}

.cantNotifySection {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: $zindex-hamburger;
  width: auto; /* Ensures it adapts to content */
  max-width: 90vw; /* Prevents it from being too wide on small screens */
  padding: 8px;
  background: $subMenuLinkColor;
  border-left: 4px solid $primeContrast;
  border-radius: $border-radius-toggle;
  box-shadow: 0 2px 12px 0 $case-shadow;
  transform: translate(-50%, -50%);
  transition: opacity 0.5s ease-in-out;
  @media (max-width: $mobile) {
    width: 100%;
  }
}

.profLogoCompany {
  gap: 8px;
}

.innerCircle {
  display: inline-block;
  width: 56px;
  height: 56px;
  margin:3px;
  border-radius: $border-radius-round;
}

.profLogo{
  width: 56px;
  height: 56px;
  border-radius: $border-radius-round;
}

.handSymbol {
  cursor: pointer;
}

.spSelected {
  margin-top: 8px;
}

.initialLogoContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 54px;
  height: 54px;
  color: $white;
  font-weight: $bold;
  font-size: $fontSize-36;
  background: $violet-light;
  border-radius: $border-radius-bigger;
}

.visibilityToggleIcon {
  cursor: pointer;
  transition: transform 0.3s, opacity 0.3s;
  path {
    fill: $buttonTextColor;
  }
}
